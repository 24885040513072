.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-description {
  color: black;
  font-size: 16px;
  max-width: 720px;
  padding: 0 20px;
}
.App-title {
  color: #ff9000;
  font-size: 50px;
  max-width: 700px;
}
.App-logo {
  border-radius: 50%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
